let THEME_ID_DT = "dt";

export const ENV_STAGE = "stage";
export const ENV_DEV = "dev";
export const ENV_UAT = "uat";
export const ENV_PROD = "prod";
export const ENV_PROD_BLUE = "prod_blue";

//backend environment
export const env = ENV_PROD;

let backendUrlMap = {};
backendUrlMap[ENV_PROD] = "https://svc.digitap.ai/bank-data";
backendUrlMap[ENV_PROD_BLUE] = "https://svc.digitap.ai/bank-data/blue";
backendUrlMap[ENV_STAGE] = "https://svcstage.digitap.work";
backendUrlMap[ENV_UAT] = "https://svcdemo.digitap.work/bank-data";
backendUrlMap[ENV_DEV] = "http://13.235.226.147";


export const controllerUrl = backendUrlMap[env];;

export const getThemeId = () => {
    return THEME_ID_DT;
}

//colors
let primaryBtnBgColorMap = {};
primaryBtnBgColorMap[THEME_ID_DT] = "#52a3c5";

let primaryBtnTextColorMap = {};
primaryBtnTextColorMap[THEME_ID_DT] = "#ffffff";

let loaderColorMap = {};
loaderColorMap[THEME_ID_DT] = "#52a3c5";

let infoTitleTextColorMap = {};
infoTitleTextColorMap[THEME_ID_DT] = "#52a3c5";

export const getPrimaryBtnBgColor = () => {
    return primaryBtnBgColorMap[getThemeId()];
}

export const getPrimaryBtnTextColor = () => {
    return primaryBtnTextColorMap[getThemeId()]
}

export const getLoaderColor = () => {
    return loaderColorMap[getThemeId()]
}

export const getInfoTitleTextColor = () => {
    return infoTitleTextColorMap[getThemeId()]
}

//title
let titleMap = {};
titleMap[THEME_ID_DT] = "Digitap";

export const getTitle = () => {
    return titleMap[getThemeId()]
}

//images
export const getImagesPath = () => {
    return './static/images/' + getThemeId();
}

export const getClientNameIconPath = () => {
    return require(getImagesPath() + '/username_icon.png');
}

export const getDropDownIconPath = () => {
    return require(getImagesPath() + '/dropdown_icon.png');
}

export const getTokenIconPath = () => {
    return require(getImagesPath() + '/password_icon.png');
}

export const getErrorIconPath = () => {
    return require(getImagesPath() + '/error_icon.png');
}

export const getLogoPath = () => {
    return require(getImagesPath() + '/logo.png');
}

//fonts
let fontMap = {};
fontMap[THEME_ID_DT] = "roboto";

export const getFontRegular = () => {
    return fontMap[getThemeId()] + "regular";
}

export const getFontMedium = () => {
    return fontMap[getThemeId()] + "medium";
}

export const getFontBold = () => {
    return fontMap[getThemeId()] + "bold";
}