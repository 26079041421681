import React, { useState, useEffect } from "react";

import { backgroundBorderColorTheme, fontMedium, fontRegular, fontBold } from '../utils/AppStyle';
import { isNullOrEmpty } from "../utils/appUtil";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { clientNameErr, tokenErr, clientNameHint, startMonthHint, dateRangeHint, endMonthHint, durationErr, tokenHint, destinationHint, destinationErr, durationHint } from "../utils/Strings";
import { btnProceed } from "../utils/Strings";
import { GetUrl } from "../utils/appUtil";
import { getDropDownIconPath } from '../Config';

const DemoHome = () => {
    let history = useHistory();
    const [type, setType] = useState('text');
    const [clientNameErrMsg, setClientNameErrMsg] = useState(clientNameErr);
    const [tokenErrMsg, setTokenErrMsg] = useState(tokenErr);
    const [clientName, setClientName] = useState('');
    const [token, setToken] = useState('');
    const [isClientNameError, setClientNameError] = useState(false);
    const [isTokenError, setTokenError] = useState(false);
    const [isDestinationError, setDestinationError] = useState(false);
    const [isDurationError, setDurationError] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [startMonth, setStartMonth] = useState(new Date());
    const [endMonth, setEndMonth] = useState(new Date());
    const [duration, setDuration] = useState("");
    const [destination, setDestination] = useState("choice");

    useEffect(() => {
        //setDuration("3");
        setDates();
    }, []);

    /* When pressed enter automatically Submit */
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit()
        }
    }

    const handleChange = (e) => {
        let value = e.target.value;
        let nt = e.target.name;
        if (nt === 'setClientName') {
            setClientName(value);
        }
        else if (nt === 'setToken') {
            setToken(value);
        }
    }

    const handleDestination = (event) => {
        setDestination(event.target.value);
    }

    const handleDuration = (e) => {
        setDuration(e.target.value);
        if (e.target.value) {
            const duration = parseInt(e.target.value);
            console.log(moment().subtract(duration, 'months'));
            const endMonth = moment().format('YYYY-MM');
            const startMonth = moment().subtract(duration, 'months').format('YYYY-MM');
            console.log("startMonth:" + startMonth);
            console.log("endMonth:" + endMonth);
            setStartMonth(startMonth);
            setEndMonth(endMonth);
        } else {

        }
    }

    const setDates = () => {
        const dur = "3";
        setDuration(dur);
        const endMonth = moment().format('YYYY-MM');
        const startMonth = moment().subtract(dur, 'months').format('YYYY-MM');
        console.log("startMonth:" + startMonth);
        console.log("endMonth:" + endMonth);
        setStartMonth(startMonth);
        setEndMonth(endMonth);
    }

    const handleSubmit = () => {

        setClientNameError(false);
        setTokenError(false);
        setDestinationError(false);
        setDurationError(false);

        if (isNullOrEmpty(clientName)) {
            setClientNameError(true)
            //setClientNameErrMsg("Please enter " + clientNameText);
        }
        else if (isNullOrEmpty(destination)) {
            setDestinationError(true)
            //setClientNameErrMsg("Please enter " + clientNameText);
        }
        else if (isNullOrEmpty(duration)) {
            setDurationError(true)
            //setClientNameErrMsg("Please enter " + clientNameText);
        }
        else if (isNullOrEmpty(token)) {
            setTokenError(true)
            //setTokenErrMsg("Please enter your " + tokenText);
        }
        else {

            const data = {
                client_name: clientName,
                demo_token: token,
                destination: destination,
                start_month: startMonth,
                end_month: endMonth

                //start_month: startMonth.getFullYear() + "-" + (startMonth.getMonth() + 1),
                //end_month: endMonth.getFullYear() + "-" + (endMonth.getMonth() + 1)
            }

            GetUrl(data, history);
        }
    }

    return (
        <div>
            <div className="centerContent">
                <div className="actionCard">
                    <div>
                        <h5 className="start-demo" style={fontBold}>Bank Statement Product Demo</h5>
                        <div className="home-content" style={fontRegular}>

                            <p>Digitap Bank Statement Product helps to get the bank transactions data from the Bank Statements. This helps the Financial Institutions in the Credit Underwriting (like Salary & income verification, etc)</p>

                            <p>User can provide the statements through one of these methods:</p>
                            <ol>
                                <li>Statement Upload</li>
                                <li>NetBanking</li>
                                {/* <li>Email Statement</li> */}
                                <li>Account Aggregator</li>
                            </ol>


                            <p>In Statement upload, user uploads the PDF Statements for the required date range himself.</p>

                            <p>In Netbanking Method, user provides the login credentials for the Bank and Digitap logins and downloads the data.
 (Note: Digitap does not save the login credentials and does not use the data after the session)</p>

                            {/* <p>In Email Statement Method, user grants permission to access the Email, Digitap downloads the relavent Bank Emails and its statements.
 (Note: Digitap only seeks read-only permissions and permission is also revoked immediately after the session)</p>


                            <p>Email Statement Method is preferred as it is more convenient.</p> */}


                            <p>Once the statements are received, Digitap converts the statements into reports which would be readily used by Financial Institutions.</p>

                        </div>
                        <div>
                            <div className="home-div">
                                <table style={{ "width": "100%", "maxWidth":"700px" }}>
                                    <tbody>
                                        <tr>
                                            <td align="right" style={{ verticalAlign: "top" }}>
                                                <div className="home-field-title">
                                                    <span style={fontMedium}>{clientNameHint}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <div className={(isClientNameError === true) ? "inputStyleDivErr" : "inputStyleDiv"}>
                                                        <input className="inputStyle" placeholder={clientNameHint} style={fontMedium} value={clientName} name='setClientName' onKeyDown={(e) => handleKeyDown(e)} onChange={handleChange} required />

                                                    </div>
                                                    {
                                                        (isClientNameError === true) ?
                                                            <span className="errorMsgStyle" style={fontMedium}>{clientNameErrMsg}</span> : null
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td align="right" style={{ verticalAlign: "top" }}>
                                                <div className="home-field-title">
                                                    <span style={fontMedium}>{destinationHint}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <div className="inputStyleDiv">
                                                        <select value={destination} className="inputStyle" onChange={handleDestination} style={fontMedium}>
                                                            <option value="">Select Method</option>
                                                            <option value="netbanking">NetBanking</option>
                                                            <option value="statementupload">Statement Upload</option>
                                                            <option value="emailstatement">Email Statement</option>
                                                            <option value="accountaggregator">Account Aggregator</option>
                                                            <option value="choice">Choice</option>
                                                        </select>
                                                        <div className="float-right-icon" style={{ top: '7px', right: '5px', pointerEvents: 'none' }}>
                                                            <img src={getDropDownIconPath()} className="homeDropdownIcon" alt="dropdown" />
                                                        </div>
                                                    </div>
                                                    {
                                                        (isDestinationError === true) ?
                                                            <span className="errorMsgStyle" style={fontMedium}>{destinationErr}</span> : null
                                                    }
                                                </div>
                                            </td>
                                        </tr> */}
                                        {/* <tr>
                                        <td align="right" style={{ verticalAlign: "top" }}>
                                            <div className="home-field-title">
                                                <span style={fontMedium}>{dateRangeHint}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ display: 'inline-block', width: '45%' }}>
                                                <div className="inputStyleDiv">
                                                    <DatePicker
                                                        name="start_month"
                                                        autoComplete="off"
                                                        label=""
                                                        type="text"
                                                        className="inputStyle"
                                                        placeholderText={startMonthHint}
                                                        selectsStart
                                                        selected={startMonth}
                                                        onChange={date => setStartMonth(date)}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ display: 'inline-block', width: '45%', float: 'right' }}>
                                                <div className="inputStyleDiv">
                                                    <DatePicker
                                                        name="end_month"
                                                        autoComplete="off"
                                                        label=""
                                                        type="text"
                                                        className="inputStyle"
                                                        minDate={startMonth}
                                                        placeholderText={endMonthHint}
                                                        selectsStart
                                                        selected={endMonth}
                                                        onChange={date => setEndMonth(date)}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr> */}
                                        <tr>
                                            <td align="right" style={{ verticalAlign: "top" }}>
                                                <div className="home-field-title">
                                                    <span style={fontMedium}>{durationHint}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <div className="inputStyleDiv">
                                                        <select value={duration} className="inputStyle" onChange={handleDuration} style={fontMedium}>
                                                            <option value="3">Last 3 Full months &amp; Current month</option>
                                                            <option value="6">Last 6 Full months &amp; Current month</option>
                                                            <option value="9">Last 9 Full months &amp; Current month</option>
                                                            <option value="13">Last 12 Full months &amp; Current month</option>   
                                                        </select>
                                                        <div className="float-right-icon" style={{ top: '5px', right: '5px', pointerEvents: 'none' }}>
                                                            <img src={getDropDownIconPath()} className="homeDropdownIcon" alt="dropdown" />
                                                        </div>

                                                    </div>
                                                    {
                                                        (isDurationError === true) ?
                                                            <span className="errorMsgStyle" style={fontMedium}>{durationErr}</span> : null
                                                    }
                                                </div>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td align="right" style={{ verticalAlign: "top" }}>
                                                <div className="home-field-title">
                                                    <span style={fontMedium}>{tokenHint}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <div className={(isTokenError === true) ? "inputStyleDivErr" : "inputStyleDiv"}>
                                                        <input className="inputStyle" type={type} placeholder={tokenHint} style={fontMedium} value={token} name='setToken' onKeyDown={(e) => handleKeyDown(e)} onChange={handleChange} required />

                                                    </div>
                                                    {
                                                        (isTokenError === true) ?
                                                            <span className="errorMsgStyle" style={fontMedium}>{tokenErrMsg}</span> : null
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{marginTop:"10px"}}>
                                <span className="termsStyle" style={fontMedium}>By clicking Proceed you agree to our </span><a href="https://www.digitap.ai/termsConditions.html" className="tcStyle" style={fontMedium}>Terms of Use</a><span className="termsStyle" style={fontMedium}> and </span><a href="https://www.digitap.ai/privacyPolicy.html" className="tcStyle" style={fontMedium}>Privacy Policy</a>
                                <br /><br />
                                <center>
                                    <button className="full-primary-button" style={backgroundBorderColorTheme} onClick={handleSubmit}>{btnProceed}</button>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(DemoHome);